const drawerToggle = () => {
	const drawer = document.querySelector(`#hamburger-menu`);
	drawer.addEventListener("click", () => {
		const elmClassList = document.querySelector("#Header").classList;
		if (elmClassList.contains("nav-close")) {
			elmClassList.remove("nav-close");
			elmClassList.add("nav-open");
			drawer.classList.add("active");
		} else if (elmClassList.contains("nav-open")) {
			elmClassList.remove("nav-open");
			elmClassList.add("nav-close");
			drawer.classList.remove("active");
		}
	});
};

const drawerCloseOnly = (target) => {
	target.addEventListener("click", () => {
		const elmClassList = document.querySelector("#Header").classList;
		const drawer = document.querySelector(`#hamburger-menu`);
		if (elmClassList.contains("nav-open")) {
			elmClassList.remove("nav-open");
			elmClassList.add("nav-close");
			drawer.classList.remove("active");
		}
	});
};

window.addEventListener("load", () => {
	const burgerButton = document.querySelector(`#hamburger-menu`);
	burgerButton.removeAttribute("disabled");
});

window.onload = () => {
	const logo = document.querySelector("#logo");
	const courseIntro = document.querySelector("#course-intro");
	drawerCloseOnly(logo);
	drawerCloseOnly(courseIntro);
};

const Menu = () => {
	window.addEventListener("load", drawerToggle);
};

export default Menu;
