//import js
import "@babel/polyfill";
//import "core-assets/js/stable";
import "regenerator-runtime/runtime.js";
import "scroll-behavior-polyfill";
import "../css/scss/common/main.scss";
import "../css/scss/frame/floatBanners.scss";
import "../css/scss/frame/footer.scss";
import "../css/scss/frame/hamburgerMenu.scss";
import "../css/scss/frame/header.scss";
//pages
import "../css/scss/pages/effects.scss";
import "../css/scss/pages/faq.scss";
import "../css/scss/pages/news.scss";
import "../css/scss/pages/staff.scss";
import "../css/scss/pages/top/banner-courses.scss";
import "../css/scss/pages/top/banner-news.scss";
import "../css/scss/pages/top/banner-user-review.scss";
import "../css/scss/pages/top/banner-explainer-video.scss";
import "../css/scss/pages/top/banner01.scss";
import "../css/scss/pages/top/banner02.scss";
import "../css/scss/pages/top/banner03.scss";
import "../css/scss/pages/top/banner04.scss";
import "../css/scss/pages/top/banner05.scss";
import "../css/scss/pages/top/banner06.scss";
import "../css/scss/pages/top/banner07.scss";
import "../css/scss/pages/top/banner08.scss";
import "../css/scss/pages/top/banner09.scss";
import "../css/scss/pages/top/banner10.scss";
import "../css/scss/pages/top/top.scss";
import Menu from "./common/Menu.js";
import top from "./pages/top.js";

Menu();
top();
